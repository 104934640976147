<template>
  <category-risk
    category="hippa"
    title="HIPPA Security"
    :banner="banner"
  />
</template>

<script>
  export default {
    name: 'DashboardHippa',

    data: () => ({
      banner: {
        title: 'What is HIPPA Security?',
        body: "The HIPAA Security Rule establishes national standards to protect individuals' electronic personal health information that is created, received, used, or maintained by a covered entity."
      }
    }),

    components: {
      CategoryRisk: () => import('../component/Category'),
    }
  }
</script>
